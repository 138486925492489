import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { LocalStorageService } from '@services/local-storage.service';

import { MSessionStorageService } from '@mercadona/core/storage';

@Injectable({
  providedIn: 'root'
})
export class UserSessionVerifierGuard implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService,
    private mSessionStorage: MSessionStorageService
  ) {}

  /**
   * Determines whether the current route can be activated.
   * Clears the current user session from both local and session storage.
   *
   * @returns {Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree}
   * Always returns true, indicating the route can be activated.
   */

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.localStorageService.clearSession();
    this.mSessionStorage.clear();
    return true;
  }
}
